import React from "react";
import "./style.scss";
import { SubscriptionBanner } from './banner/SubscriptionBanner';
import { SubscriptionContent } from './content/SubscriptionContent';
import { SubscriptionSupporter } from './number/SubscriptionSupporter';
import { SubscriptionCard } from './card/SubscriptionCard';



const SubscriptionPage = () => {

    return (
        <>
            {/* <SubscriptionBanner />
            <SubscriptionSupporter />
            <SubscriptionCard />
            <SubscriptionContent /> */}
        </>
    );
};
export default SubscriptionPage;